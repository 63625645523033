<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan vuokrakohdetta..."
      class="full-page-loader"
    ></full-page-loader>

    <!-- BASIC INFORMATION -->
    <v-form v-if="!loading" ref="form">
      <v-card class="mt-1">
        <v-card-title
          ><div>Perustiedot</div>
          <v-spacer></v-spacer>
          <router-link :to="`/apartment/overview/${$route.params.id}`">
            <v-btn color="primary">Yleisnäkymään</v-btn>
          </router-link>
        </v-card-title>
        <v-card-subtitle style="padding-bottom: 5px"
          >** Julkiset tiedot voidaan näyttää, jos kohde julkaistaan asuntoportaaleihin tai omille
          verkkosivuille</v-card-subtitle
        >
        <div style="padding-top: 0px" class="message-box-wrapper">
          <div v-if="!currentApartment.activeForRent" class="mt-1 error-text-box">
            <p style="margin-bottom: 0px" class="error--text">
              Vuokrakohde ei ole vuokrattavana, ja se ei löydy vapaiden kohteiden listauksista.
            </p>
          </div>
        </div>
        <v-container>
          <h4>Vuokrattavana</h4>
          <v-row dense class="mb-1">
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                :disabled="isDisabled('apartment', 'activeForRent')"
                v-model="currentApartment.activeForRent"
                :items="[
                  { text: 'Kyllä', val: true },
                  { text: 'Ei', val: false },
                ]"
                item-text="text"
                item-value="val"
                outlined
                dense
                label="Vuokrattavana"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'concurrentContracts')"
                v-model.number="currentApartment.concurrentContracts"
                v-only-numbers
                v-prevent-paste
                :rules="validations.required.concat(validations.positiveNumber)"
                label="Päällekkäisiä sopimuksia max"
                suffix="kpl"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4>Tyyppitiedot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-radio-group
                :disabled="isDisabled('apartment', 'vatRegister')"
                v-model="currentApartment.vatRegister"
                :mandatory="true"
                style="margin-top: 0px"
                hide-details
              >
                <v-radio label="Arvonlisäverovelvollinen" :value="true"></v-radio>
                <v-radio label="Ei arvonlisäverovelvollinen" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="$route.name != 'Luo kohde'" cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                :disabled="isDisabled('apartment', 'condominium')"
                v-model="currentApartment.condominium"
                item-text="name"
                item-value="_id"
                :items="condominiums"
                label="Taloyhtiö / Kiinteistö"
                return-object
                @change="changeAddress($event)"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                :disabled="isDisabled('apartment', 'houseType')"
                v-model="currentApartment.houseType"
                outlined
                dense
                :items="globalValues.houseTypes"
                label="Vuokrakohteen tyyppi **"
                :rules="validations.required"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentApartment.doorType"
                outlined
                dense
                :items="globalValues.doorTypes"
                label="Oven tyyppi **"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentApartment.shareNumbers"
                placeholder="esim. 1234-1235"
                label="Osakenumerot"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                :disabled="isDisabled('apartment', 'currentCondition')"
                v-model="currentApartment.currentCondition"
                outlined
                dense
                :items="[
                  { val: 1, text: 'Hyvä' },
                  { val: 2, text: 'Tyydyttävä' },
                  { val: 3, text: 'Välttävä' },
                ]"
                item-value="val"
                item-text="text"
                label="Vuokrakohteen kunto **"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentApartment.area"
                type="number"
                step="0.1"
                :rules="validations.required.concat(validations.positiveNumber)"
                label="Pinta-ala **"
                suffix="m²"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              v-if="currentApartment.houseType != 'Kiinteistö'"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-text-field
                v-model="currentApartment.floorPlan"
                placeholder="esim. 1h + kk"
                label="Vuokrakohteen kokoonpano **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Osoite</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="nominatimAddress"
                :disabled="isDisabled('apartment', 'address')"
                :items="foundAddresses"
                :item-text="nominatimHelpers.getAddressListName"
                outlined
                label="Hae osoitteella"
                dense
                return-object
                no-filter
                @change="
                  setAddress($event);
                  clearInput();
                "
                @input.native="getAddresses"
                hide-details
              ></v-autocomplete> </v-col
          ></v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'address')"
                v-model="currentApartment.address"
                :rules="validations.required"
                label="Osoite **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              v-if="currentApartment.houseType != 'Kiinteistö'"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-text-field
                :disabled="isDisabled('apartment', 'apartmentNumber')"
                v-model="currentApartment.apartmentNumber"
                label="Vuokrakohteen numero"
                :rules="validations.required"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'zipCode')"
                v-model="currentApartment.zipCode"
                :rules="validations.required"
                label="Postinumero **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'city')"
                v-model="currentApartment.city"
                :rules="validations.required"
                label="Kaupunki **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentApartment.neighborhood"
                label="Kaupunginosa **"
                outlined
                dense
                disabled
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentApartment.country"
                outlined
                clearable
                label="Maa"
                dense
                disabled
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              v-if="currentApartment.houseType != 'Kiinteistö'"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-text-field
                v-model.number="currentApartment.floor"
                v-only-numbers-and-zero
                v-prevent-paste
                label="Kerros **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Lisätiedot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentApartment.roomCount"
                v-only-numbers
                v-prevent-paste
                :rules="validations.emptyOrPositiveNumber"
                label="Huoneiden/tilojen määrä **"
                suffix="kpl"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              v-if="currentApartment.houseType != 'Kiinteistö'"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-select
                v-model="currentApartment.animals"
                label="Eläimet sallittu **"
                :items="[
                  { val: true, text: 'Kyllä' },
                  { val: false, text: 'Ei' },
                ]"
                item-value="val"
                item-text="text"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col
              v-if="currentApartment.houseType != 'Kiinteistö'"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-select
                v-model="currentApartment.balcony"
                label="Parveke **"
                :items="[
                  { val: true, text: 'Kyllä' },
                  { val: false, text: 'Ei' },
                ]"
                item-value="val"
                item-text="text"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                v-model="currentApartment.furnished"
                label="Vuokrataan kalustettuna **"
                outlined
                dense
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>

          <h4 class="mt-1">Kuvaus</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3">
              <v-textarea
                v-model="currentApartment.description"
                label="Vuokrakohteen kuvaus **"
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Huoneet tai muut tilat</v-card-title>
        <v-container>
          <h4 v-if="activeRooms.length > 0">Huoneet</h4>

          <v-row dense v-for="(room, idx) in activeRooms" :key="`${idx}` + 'a'">
            <v-col cols="6" md="5" lg="3" xl="2">
              <small
                v-if="!isDisabled('apartment', 'rooms')"
                class="error--text"
                @click="removeRoom(idx, room.name)"
                style="cursor: pointer; margin-bottom: 3px"
                >Poista</small
              >

              <v-text-field
                :disabled="isDisabled('apartment', 'rooms')"
                dense
                outlined
                label="Nimi"
                v-model="room.name"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn
            :disabled="isDisabled('apartment', 'rooms')"
            small
            color="primary"
            :class="activeRooms.length > 0 ? 'mt-2' : ''"
            @click="addRoom"
            >Lisää huone</v-btn
          >
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title> Hinnat ja vastikkeet</v-card-title>
        <v-container>
          <h4>Hinnat ja vuokra</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentApartment.priceWithTaxes"
                type="number"
                step="0.01"
                label="Velaton hinta veroineen"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentApartment.estimatedPrice"
                type="number"
                step="0.01"
                label="Nykyinen hinta-arvio"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'estimatedRent')"
                v-model.number="currentApartment.estimatedRent"
                type="number"
                step="0.01"
                label="Markkinavuokra **"
                outlined
                dense
                suffix="€/kk"
                hide-details
                :rules="validations.emptyOrPositiveNumber"
              ></v-text-field>
              <small v-if="currentApartment.area && currentApartment.estimatedRent"
                >Neliövuokra:
                {{ (currentApartment.estimatedRent / currentApartment.area).toFixed(2) }}
                €/m²</small
              >
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                :disabled="isDisabled('apartment', 'electricityType')"
                v-model.number="currentApartment.electricityType"
                :items="globalValues.electricityTypes"
                item-text="text"
                item-value="val"
                label="Sähkömaksu **"
                outlined
                dense
                @change="currentApartment.fixedElectricity = null"
                hide-details
              ></v-select>
            </v-col>

            <v-col
              v-if="currentApartment.electricityType == 2"
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="2"
            >
              <v-text-field
                :disabled="isDisabled('apartment', 'fixedElectricity')"
                v-model.number="currentApartment.fixedElectricity"
                type="number"
                step="0.01"
                label="Kiinteä sähkömaksu **"
                :rules="validations.required.concat(validations.positiveNumber)"
                outlined
                dense
                suffix="€/kk"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'deposit')"
                v-model.number="currentApartment.deposit"
                type="number"
                step="0.01"
                label="Vakuus **"
                outlined
                dense
                suffix="€"
                hide-details
                :rules="validations.emptyOrPositiveNumber"
              ></v-text-field>
            </v-col>
          </v-row>

          <h4>Hoitovastikkeet ja vesimaksut</h4>
          <v-row dense>
            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                v-model.number="currentApartment.maintenanceCharge"
                type="number"
                step="0.01"
                label="Nykyinen hoitovastike **"
                outlined
                dense
                hide-details
                suffix="€/kk"
                :rules="validations.emptyOrPositiveNumber"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6" lg="3" xl="2">
              <v-btn
                color="primary"
                @click.stop="showChargeDialog('hoitovastikkeet', 'oldMaintenanceCharges')"
                outlined
                small
                >Aikaisemmat hoitovastikkeet
              </v-btn>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentApartment.waterCharge"
                type="number"
                step="0.01"
                label="Nykyinen vesimaksu"
                outlined
                dense
                hide-details
                suffix="€/kk"
                :rules="validations.emptyOrPositiveNumber"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-btn
                class="mb-3"
                color="primary"
                @click.stop="showChargeDialog('vesimaksut', 'oldWaterCharges')"
                outlined
                small
                >Aikaisemmat vesimaksut</v-btn
              >
            </v-col>
          </v-row>

          <!-- Apartment loans-->
          <h4 class="mt-2">Rahoitusvastikkeet</h4>
          <apartment-loans></apartment-loans>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Laskutus</v-card-title>
        <v-card-subtitle v-if="currentUser.currentAccount.settings.outgoingPayments"
          >Vuokrakohdekohtainen maksuviite muodostetaan lähtevien maksujen viitenumeroksi
          automaattisesti.</v-card-subtitle
        >

        <v-container v-if="currentUser.currentAccount.settings.outgoingPayments">
          <v-row dense>
            <v-col cols="12" sm="6" md="6" lg="4" xl="3">
              <div v-if="currentApartment.paymentReference">
                <p>Vuokrakohdekohtainen viitenumero on:</p>
                <h3 class="success--text">{{ currentApartment.paymentReference }}</h3>
              </div>
              <p v-else>Viitenumeroa ei ole vielä muodostettu.</p>
            </v-col>
          </v-row>
        </v-container>

        <v-card-subtitle
          >Jos olet valinnut tilin laskutusasetuksista vuokrakohdekohtaisen
          kustannuspaikkalaskennan, käytetään kyseisen vuokrakohteen laskutuksessa tätä
          kustannuspaikkaa.</v-card-subtitle
        >

        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'costCentre')"
                v-model="currentApartment.costCentre"
                label="Kustannuspaikka"
                outlined
                dense
                hide-details
              ></v-text-field>
              <p>
                <small
                  >Muokattaessa kustannuspaikka ei päivity automaattisesti laskuille. Mene tilin
                  asetukset -> laskutus ja tarkista kustannuspaikan asetukset.</small
                >
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Muut tiedot</v-card-title>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="3" sm="6" lg="3" xl="2">
              <v-menu
                ref="purchaseDateMenu"
                v-model="purchaseDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(currentApartment.purchaseDate)"
                    v-bind="attrs"
                    v-on="on"
                    label="Kohteen ostopäivä"
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    hide-details
                    clearable
                    @click:clear="currentApartment.purchaseDate = ``"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentApartment.purchaseDate"
                  first-day-of-week="1"
                  @input="purchaseDateMenu = false"
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3" sm="6" lg="3" xl="2">
              <v-menu
                ref="lastVisitDateMenu"
                v-model="lastVisitDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :disabled="isDisabled('apartment', 'lastVisit')"
                    :value="formatDate(currentApartment.lastVisit)"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    label="Viimeisin käynti asunnolla"
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    hide-details
                    clearable
                    @click:clear="currentApartment.lastVisit = ``"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentApartment.lastVisit"
                  first-day-of-week="1"
                  @input="lastVisitDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!-- Owners -->
      <v-card class="mt-2">
        <v-card-title>Omistajat / Toimeksiantajat</v-card-title>
        <v-card-subtitle class="error--text"
          >Lisää omistajat, jos kohteella on muita omistajia. Mikäli kohteen omistaa kokonaan tilin
          omistaja, omistajia ei ole tarpeellista merkitä.</v-card-subtitle
        >
        <v-container>
          <div v-for="(owner, index) in currentApartment.owners" :key="index + 'b'">
            <v-row dense>
              <v-col cols="6" md="4" lg="3" xl="2" :class="index !== 0 ? 'mt-1' : ''">
                <v-autocomplete
                  :value="owner.ownerId"
                  :disabled="isDisabled('apartment', 'owners')"
                  item-text="name"
                  item-value="_id"
                  outlined
                  small-chips
                  :items="activeLandlords"
                  return-object
                  label="Muu omistaja / toimeksiantaja"
                  dense
                  hide-details
                  @input.native="getLandlords"
                  @change="setOwner($event, owner)"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col ref="ownerField" cols="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="owner.name"
                  outlined
                  dense
                  :rules="validations.required"
                  label="Nimi"
                  disabled
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="4" lg="3" xl="2">
                <div class="details-container">
                  <v-text-field
                    :disabled="isDisabled('apartment', 'owners')"
                    v-model.number="owner.holding"
                    outlined
                    dense
                    type="number"
                    max="100"
                    step="0.01"
                    label="Omistusosuus %"
                    hide-details
                    :rules="
                      validations.required
                        .concat(validations.positiveNumber)
                        .concat(validations.maxNumber(100))
                    "
                  ></v-text-field>

                  <v-icon
                    v-if="!isDisabled('apartment', 'owners')"
                    style="margin-bottom: 5px"
                    class="ml-2"
                    color="error"
                    small
                    @click="removeOwner(index)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="8" lg="6" xl="4">
                <v-divider style="margin-top: 4px; margin-bottom: -3px"></v-divider>
              </v-col>
            </v-row>
          </div>

          <v-row dense :class="currentApartment.owners.length > 0 ? 'mt-4' : ''">
            <v-col cols="12" md="4">
              <v-btn
                :disabled="isDisabled('apartment', 'owners')"
                small
                color="primary"
                @click="addOwner"
                >Lisää omistaja</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <!--  Assignment form -->
      <apartment-assignment-form
        v-if="isAuthorized('realtor', 'assignment')"
      ></apartment-assignment-form>

      <!-- Sell apartment
      <v-card class="mt-2">
        <v-card-title>Kohteen myynti</v-card-title>

        <v-container>
          <v-row dense>
            <v-col cols="6" md="4" lg="3" xl="2">
              <v-checkbox
                :disabled="isDisabled('apartment', 'salesInfo.sold')"
                v-model="apartment.salesInfo.sold"
                style="margin-top: 0px"
                label="Kohde on myyty"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col v-if="apartment.salesInfo.sold" cols="12" md="4" sm="6" lg="3" xl="2">
              <v-menu
                ref="salesDateMenu"
                v-model="salesDateMenu"
                :close-on-content-click="false"
                min-width="290"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :disabled="isDisabled('apartment', 'salesInfo.salesDate')"
                    :value="formatDate(apartment.salesInfo.salesDate)"
                    v-bind="attrs"
                    v-on="on"
                    label="Kohteen myyntipäivä"
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    v-prevent-manual-input
                    clearable
                    @click:clear="apartment.salesInfo.salesDate = null"
                    hide-details
                    :rules="validations.required"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="apartment.salesInfo.salesDate"
                  first-day-of-week="1"
                  @input="salesDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="apartment.salesInfo.sold" cols="12" md="4" sm="6" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('apartment', 'salesInfo.salesPrice')"
                v-model.number="apartment.salesInfo.salesPrice"
                type="number"
                step="0.01"
                label="Myyntihinta"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <p v-if="apartment.salesInfo.sold" class="warning text-center pa-1 mt-1">
            Kohde on tallennettu myydyksi ja se poistetaan näkyvistä. Voit kuitenkin palauttaa
            kohteen takaisin tai muokata tiettyjä kenttiä myydystä kohteesta.
          </p>
        </v-container>
      </v-card> -->
      <v-btn color="info" class="mt-5" @click="submit">{{ saveBtnText }}</v-btn>
    </v-form>

    <!-- Add old charges dialog -->
    <add-old-charges
      v-model="showAddOldChargeDialog"
      :title="chargeTitle"
      :field="chargeField"
    ></add-old-charges>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import AddOldCharges from "../../components/Apartment/AddOldCharges";
import ApartmentLoans from "../../components/Apartment/ApartmentLoans";
import nominatimHelpers from "@/utils/nominatimHelpers";
import globalValues from "../../configs/globalValues";
import { debounce } from "../../utils/helpers";
import FullPageLoader from "@/components/FullPageLoader.vue";
import validations from "@/validations";
import { mapState, mapActions, mapMutations } from "vuex";
import ApartmentAssignmentForm from "./ApartmentAssignmentForm.vue";

export default {
  props: ["save-btn-text"],

  components: {
    ApartmentAssignmentForm,
    AddOldCharges,
    ApartmentLoans,
    FullPageLoader,
  },

  mixins: [mixins],

  data() {
    return {
      showAddOldChargeDialog: false,
      chargeTitle: "",
      chargeField: "",
      addressInput: "",
      landlordInput: "",
      purchaseDateMenu: false,
      salesDateMenu: false,
      lastVisitDateMenu: false,
      edit: false,
      showFileDialog: false,
      search: "",
      menu: false,
      nominatimHelpers,
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("condominium", ["currentCondominium", "condominiums"]),
    ...mapState("apartment", ["currentApartment", "loading", "apartmentCopy"]),
    ...mapState("account", ["activeLandlords", "currentUser"]),
    ...mapState("nominatim", ["foundAddresses"]),

    isCopyApartmentForm() {
      return this.$route.query.createCopy === "true";
    },

    nominatimAddress: {
      get() {
        return this.$store.state.nominatim.nominatimAddress;
      },
      set(val) {
        this.setNominatimAddress(val);
      },
    },

    activeRooms() {
      return this.currentApartment.rooms.filter((el) => el.active);
    },
  },

  watch: {
    currentCondominium(val) {
      // Set condominium
      this.condominium = val || {};

      if (this.isCopyApartmentForm) {
        if (this.apartmentCopy) {
          const copy = this.apartmentCopy;

          // Leave empty
          this.setField({ field: "apartmentNumber", val: "" });

          // Copy these
          this.setField({ field: "condominium", val: copy.condominium });
          this.setField({ field: "activeForRent", val: copy.activeForRent });
          this.setField({ field: "vatRegister", val: copy.vatRegister });
          this.setField({ field: "houseType", val: copy.houseType || "" });
          this.setField({ field: "doorType", val: copy.doorType || "" });
          this.setField({ field: "currentCondition", val: copy.currentCondition || null });
          this.setField({ field: "address", val: copy.address });
          this.setField({ field: "zipCode", val: copy.zipCode });
          this.setField({ field: "city", val: copy.city });
          this.setField({ field: "country", val: copy.country || "" });
          this.setField({ field: "neighborhood", val: copy.neighborhood || "" });
          this.setField({ field: "area", val: copy.area || null });
          this.setField({ field: "floor", val: copy.floor || null });
          this.setField({ field: "balcony", val: copy.balcony == null ? null : copy.balcony });
          this.setField({ field: "animals", val: copy.animals == null ? null : copy.animals });
          this.setField({ field: "floorPlan", val: copy.floorPlan || "" });
          this.setField({ field: "roomCount", val: copy.roomCount || null });
          this.setField({ field: "furnished", val: copy.furnished || false });
          this.setField({ field: "costCentre", val: copy.costCentre || null });
          this.setField({ field: "priceWithTaxes", val: copy.priceWithTaxes || null });
          this.setField({ field: "estimatedPrice", val: copy.estimatedPrice || null });
          this.setField({ field: "estimatedRent", val: copy.estimatedRent || null });
          this.setField({ field: "electricityType", val: copy.electricityType || null });
          this.setField({ field: "fixedElectricity", val: copy.fixedElectricity || null });
          this.setField({ field: "deposit", val: copy.deposit || null });
          this.setField({ field: "maintenanceCharge", val: copy.maintenanceCharge || null });
          this.setField({ field: "waterCharge", val: copy.waterCharge || null });
          this.setField({ field: "concurrentContracts", val: copy.concurrentContracts || 1 });
          this.setField({ field: "description", val: copy.description || "" });
          this.setField({ field: "lastVisit", val: copy.lastVisit || "" });
          this.setField({ field: "purchaseDate", val: copy.purchaseDate || "" });
          this.setField({ field: "loans", val: copy.loans || null });
          const owners =
            copy.owners.map((el) => {
              return {
                name: el.name,
                holding: el.holding,
                ownerId: el.ownerId._id,
                onModel: el.onModel,
              };
            }) || [];
          this.setField({ field: "owners", val: owners || [] });

          if (copy.assignment) {
            let realtors = [];
            if (copy.assignment?.realtors) {
              realtors = copy.assignment.realtors.map((el) => {
                return {
                  name: el.name,
                  rewardPercent: el.rewardPercent,
                  realtorId: el.realtorId,
                };
              });
            }
            const assignment = { ...copy.assignment, realtors };
            this.setField({ field: "assignment", val: assignment });
          }
        } else {
          this.$router.push("/apartments");
        }
      } else {
        // Set certain fields automatically
        this.setField({ field: "condominium", val: val });
        this.setField({ field: "address", val: val.address });
        this.setField({ field: "zipCode", val: val.zipCode });
        this.setField({ field: "city", val: val.city });
        this.setField({ field: "neighborhood", val: val.neighborhood });
        this.setField({ field: "country", val: val.country || "" });
      }

      // Set loading false
      this.setLoading(false);
    },

    "currentApartment.houseType"(val) {
      if (val == "Kiinteistö") {
        this.setField({ field: "apartmentNumber", val: "" });
        this.setField({ field: "floor", val: "" });
        this.setField({ field: "balcony", val: null });
        this.setField({ field: "floorPlan", val: "" });
        this.setField({ field: "animals", val: null });
      }
    },

    "currentApartment.lastVisit"() {
      if (this.currentApartment.lastVisit) {
        this.setField({
          field: "lastVisit",
          val: new Date(new Date(this.currentApartment.lastVisit)).toISOString().substring(0, 10),
        });
      }
    },

    "currentApartment.purchaseDate"() {
      if (this.currentApartment.purchaseDate) {
        this.setField({
          field: "purchaseDate",
          val: new Date(new Date(this.currentApartment.purchaseDate))
            .toISOString()
            .substring(0, 10),
        });
      }
    },

    "currentApartment.salesInfo.salesDate"() {
      if (this.currentApartment?.salesInfo?.salesDate) {
        this.setField({
          field: "salesInfo.salesDate",
          val: new Date(new Date(this.currentApartment.salesInfo.salesDate))
            .toISOString()
            .substring(0, 10),
        });
      }
    },

    "currentApartment.salesInfo.sold"(val) {
      if (!val) {
        this.setField({
          field: "salesInfo.salesDate",
          val: null,
        });
        this.setField({
          field: "salesInfo.salesPrice",
          val: null,
        });
      }
    },

    "currentApartment.assignment.startDate"() {
      if (this.currentApartment?.assignment?.startDate) {
        this.setField({
          field: "assignment.startDate",
          val: new Date(new Date(this.currentApartment.assignment.startDate))
            .toISOString()
            .substring(0, 10),
        });
      }
    },

    "currentApartment.assignment.endDate"() {
      if (this.currentApartment?.assignment?.endDate) {
        this.setField({
          field: "assignment.endDate",
          val: new Date(new Date(this.currentApartment.assignment.endDate))
            .toISOString()
            .substring(0, 10),
        });
      }
    },

    landlordInput: debounce(function (newVal) {
      this.searchAllLandlords(`/api/v1/account/search-landlords?search=${newVal}`);
    }, 1000),

    addressInput: debounce(function (newVal) {
      this.getInfoByAddress(newVal);
    }, 400),
  },

  methods: {
    ...mapActions("apartment", ["createApartment", "updateApartment"]),
    ...mapMutations("apartment", [
      "setLoading",
      "setField",
      "addOwner",
      "deleteOwner",
      "addRoom",
      "deleteRoom",
    ]),
    ...mapActions("account", ["searchAllLandlords"]),
    ...mapActions("nominatim", ["getInfoByAddress"]),
    ...mapMutations("nominatim", ["setNominatimAddress"]),

    getLandlords(event) {
      if (this.$store.state.user != "partner") {
        this.landlordInput = event.target.value;
      }
    },

    getAddresses(event) {
      this.addressInput = event.target.value;
    },

    setAddress(data) {
      if (data) {
        const { address, zipCode, city, country } = nominatimHelpers.getAddressData(data);
        this.setField({ field: "address", val: address });
        this.setField({ field: "zipCode", val: zipCode });
        this.setField({ field: "city", val: city });
        this.setField({ field: "country", val: country });
      }
    },

    clearInput() {
      this.$nextTick(() => {
        this.setNominatimAddress(null);
      });
    },

    removeRoom(idx, name) {
      if (confirm(`Haluatko varmasti poistaa huoneen ${name}`)) {
        this.deleteRoom(idx);
        this.showPopup(`Huone '${name}' poistettu`, "success");
      }
    },

    setOwner(event, owner) {
      if (event) {
        owner.name = event.name;
        owner.ownerId = event._id;
        owner.onModel =
          String(event._id) == String(this.$store.state.account.currentUser.currentAccount._id)
            ? "Account"
            : "Partner";
      }
    },

    removeOwner(index) {
      if (
        confirm(
          "Oletko varma, että haluat poistaa omistajan? HUOM! Mikäli vuokrakohteen omistaja muuttuu tai asunto myydään, älä muuta tästä omistajaa. Poista asunto ja luo uusi asunto tilalle."
        )
      ) {
        this.deleteOwner(index);
      }
    },

    isTotal100Percents(array, percentField) {
      if (array.length == 0) return true;
      const percents = array.reduce((acc, el) => acc + el[percentField], 0);
      const roundedPercent = Math.round(percents * 100) / 100;
      const tolerance = 0.05;
      return Math.abs(roundedPercent - 100) < tolerance;
    },

    changeAddress(condominium) {
      this.setField({ field: "condominium", val: condominium });
      this.setField({ field: "address", val: condominium.address });
      this.setField({ field: "zipCode", val: condominium.zipCode });
      this.setField({ field: "city", val: condominium.city });
      this.setField({ field: "neighborhood", val: condominium.neighborhood });
      this.setField({ field: "country", val: condominium.country || "" });
    },

    showChargeDialog(title, field) {
      this.chargeTitle = title;
      this.chargeField = field;
      this.showAddOldChargeDialog = true;
    },

    async submit() {
      try {
        if (!this.isTotal100Percents(this.currentApartment.assignment.realtors, "rewardPercent"))
          return this.showPopup("Välittäjien palkkio-osuuksien pitää olla yhteensä 100%", "error");

        if (!this.isTotal100Percents(this.currentApartment.owners, "holding"))
          return this.showPopup("Omistusosuus pitää olla yhteensä 100%", "error");

        if (this.$refs.form.validate()) {
          if (this.$route.name == "Luo kohde") {
            await this.createApartment(this.currentApartment);
            if (this.isCopyApartmentForm) {
              this.$router.push("/apartments");
            } else {
              this.$router.push("/condominiums");
            }
          } else {
            let apartment = this.currentApartment;
            delete apartment.createdBy;
            delete apartment.createdAt;
            delete apartment.updatedAt;
            delete apartment.__v;
            delete apartment._id;
            delete apartment.id;

            await this.updateApartment({
              apartmentId: this.$route.params.id,
              apartment,
            });
            this.$router.push("/apartments");
          }
        } else {
          this.showPopup("Kaikki pakolliset kentät ei ole täydennetty", "error");
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    allowedDates(val) {
      const date = new Date(val);
      const today = new Date();
      if (date > today) return false;
      else return true;
    },
  },
};
</script>

<style scoped>
.delete-btn {
  cursor: pointer;
}

.details-container {
  display: flex;
}

.card-oldlist {
  height: 500px;
  overflow-y: scroll;
}

.message-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-text-box {
  border: 1px solid var(--v-error-base);
  border-radius: 4px;
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}
</style>
